import api from '../axios/axios';

export const getPaymentPlans = () => {
  return api.get('/payment/plans');
};

export const getUserCredentials = () => {
  return api.get('/payment/userCredits');
};

export const getStripeUrl = (data: {
  priceId: string;
  successUrl: string;
  cancelUrl: string;
  rewardfulClientId?: string;
}) => {
  return api.post('/payment/checkout', data);
};

export const getStripeUpgradeUrl = (data: {
  priceId: string;
  successUrl: string;
  cancelUrl: string;
}) => {
  return api.post('/payment/upgrade', data);
};

export const getPaymentSettingsUrl = (data: { returnUrl: string }) => {
  return api.post('/payment/createStripeCustomerPortal', data);
};
