import React, { useEffect, useState } from 'react';
import { Flex, Heading, Box, useDisclosure } from '@chakra-ui/react';
import { AlertModal } from '../core/AlertModal';
import { useNavigate } from 'react-router-dom';
import { getUserCredentials } from 'src/api/payment';
export interface RoleSelectionProps {
  onRoleSelected: (id: string) => void;
  roles: any[];
  isOneSided: boolean;
  ignoreCredits: boolean;
}

export const RoleSelection: React.FC<RoleSelectionProps> = ({
  onRoleSelected,
  roles,
  isOneSided,
  ignoreCredits
}) => {
  const navigate = useNavigate();
  const [hasEnoughCredits, setHasEnoughCredits] = useState(false);
  const checkCredits = async () => {
    if (!ignoreCredits) {
      const { data } = await getUserCredentials();
      const credits = data.credits;
      if (credits == 0 || credits < 1) {
        onOpen();
        setHasEnoughCredits(false);
      } else {
        setHasEnoughCredits(true);
      }
    } else {
      setHasEnoughCredits(true);
    }
  };
  const { isOpen, onClose, onOpen } = useDisclosure();
  useEffect(() => {
    if (roles != null && roles.length > 0) {
      const role = roles[0];
      onRoleSelected(role.name);
    }

    checkCredits().then();
  }, [roles, isOneSided]);
  return (
    <>
      <AlertModal
        isOpen={isOpen}
        onAccept={() => {
          onClose();
          navigate('/dashboard/membership');
        }}
        acceptText="Continue"
        title="You don't have enough credits to send a contract."
        variant="warning"
      />
      <Heading textAlign="center" mb="32px" color="brand.primary">
        What's your role for this song?
      </Heading>
      {roles.map((role, index) => {
        if (!isOneSided || (isOneSided && index === 0)) {
          return (
            <Flex
              key={role.name}
              mb="24px"
              py="20px"
              cursor="pointer"
              onClick={() => {
                if (hasEnoughCredits) {
                  onRoleSelected(role.name);
                } else {
                  checkCredits().then();
                }
              }}
              data-heap-id={`role.${role.label}`}
              w={{ base: '100%', lg: '350px' }}
              borderRadius="6px"
              border="1px solid"
              borderColor="brand.primary"
              justifyContent="center"
              color="brand.primary"
              position="relative"
              _hover={{
                bg: 'brand.primary',
                color: 'white'
              }}
            >
              I'm the {role.label}
            </Flex>
          );
        } else {
          return <Box key={index}></Box>;
        }
      })}
    </>
  );
};
